import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import {
  closeModal,
  setCaller,
  setCallInProgress,
  setIsCalling,
  setIsReceivingCall,
} from "state/call";
import { useSocket } from "context/SocketProvider";
import peer from "services/peer";
import { toast } from "react-toastify";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const InCallModal = () => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const {
    modalOpen,
    isCalling,
    isReceivingCall,
    callInProgress,
    calleeInfo,
    caller,
  } = useSelector((state) => state.call);
  const currentUser = useSelector((state) => state.auth.user);
  const [micOn, setMicOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  // const [remoteMicOn, setRemoteMicOn] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width:992px)");
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const candidateQueue = useRef([]);
  const [isPeerConnectionReady, setIsPeerConnectionReady] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullScreenRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      fullScreenRef.current.requestFullscreen();
      setIsFullScreen(true);
    } else {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (localVideoRef.current && localStream) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEffect(() => {
    peer.setRemoteStreamCallback((remoteStream) => {
      console.log("got the remote stream:", remoteStream);

      setRemoteStream(remoteStream);

      return () => {
        peer.setRemoteStreamCallback(null);
      };
    });
  }, [socket]);

  useEffect(() => {
    if (modalOpen && !isReceivingCall) {
      handleCallUser();
    }
    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [modalOpen]);

  const handleCallUser = async () => {
    let stream;
    try {
      // Access media devices
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);
    } catch (error) {
      // Handle only media access errors here
      handleMediaError(error);
      console.error("error accessing media devices :", error);
      return; // Exit if media access failed
    }

    try {
      // Create and send offer after media access is successful
      peer.createPeerConnection();
      peer.addLocalStream(stream);
      const offer = await peer.getOffer();
      const payload = {
        callerId: calleeInfo.id,
        rtcMessage: offer,
        user: {
          id: currentUser.id,
          name: currentUser.username,
        },
      };

      socket.emit("offer", payload);
    } catch (error) {
      // Handle errors related to offer creation/sending
      console.error("Error during the offer process", error);
      alert("An error occurred while making the call. Please try again.");
    }
  };

  const handleMediaError = (error) => {
    if (error.name === "NotAllowedError") {
      alert("Permission to access media devices was denied.");
      toast.error("Permission to access media devices was denied.");
    } else if (error.name === "NotFoundError") {
      alert("No media devices found. Please check your camera and microphone.");
      toast.error("No media devices found.");
    } else {
      alert("An error occurred while accessing media devices.");
      toast.error("An error occurred while accessing media devices.");
    }
  };

  const handleAnswerCall = async () => {
    let stream;
    try {
      dispatch(setIsReceivingCall(false));
      dispatch(setCallInProgress(true));

      // Access media devices (audio/video)
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);
      console.log("Local Stream:", stream);
    } catch (error) {
      // Handle only media access errors here
      handleMediaError(error);
      return; // Exit if media access failed
    }

    try {
      peer.createPeerConnection();
      peer.addLocalStream(stream);
      setIsPeerConnectionReady(true);
      // Proceed with answering the call after media access is successful
      const answer = await peer.getAnswer(caller?.rtcMessage);

      const payload = {
        callerId: caller.user.id, // ID of the caller
        rtcMessage: answer, // The generated WebRTC answer
        user: {
          id: currentUser.id,
          name: currentUser.username,
        },
      };

      socket.emit("answer", payload);
      while (candidateQueue.current.length > 0) {
        const candidate = candidateQueue.current.shift();
        await peer.addIceCandidate(candidate);
      }
    } catch (error) {
      // Handle errors related to answering the call
      console.error("Error answering call:", error);
      alert("An error occurred while answering the call. Please try again.");
    }
  };

  const handleIceCandidate = useCallback(
    (payload) => {
      const candidate = payload?.rtcMessage;
      if (isPeerConnectionReady && peer?.peer?.remoteDescription) {
        console.log("Adding ICE candidate directly:", candidate);
        peer.addIceCandidate(candidate);
      } else {
        console.log("Queueing ICE candidate:", candidate);
        candidateQueue.current.push(candidate);
      }
    },
    [isPeerConnectionReady]
  );

  useEffect(() => {
    if (socket) {
      socket.on("ice-candidate", handleIceCandidate);
      socket.on("offer", async (payload) => {
        try {
          const offer = payload.rtcMessage;
          console.log("got offer", payload);

          console.log("offer", offer);
          dispatch(setIsReceivingCall(true));
          dispatch(setCaller(payload));
        } catch (error) {
          console.error("error getting proper offer", error);
        }
      });

      socket.on("answer", async (payload) => {
        try {
          console.log("call accepted");
          console.log("received answer", payload.rtcMessage);
          dispatch(setIsCalling(false));
          dispatch(setCallInProgress(true));
          await peer.setRemoteDescription(payload.rtcMessage);
          setIsPeerConnectionReady(true); // Set peer connection as ready after setting remote description

          // Process any queued ICE candidates
          while (candidateQueue.current.length > 0) {
            const candidate = candidateQueue.current.shift();
            await peer.addIceCandidate(candidate);
          }
        } catch (error) {
          console.error("error getting proper answer", error);
        }
      });

      return () => {
        socket.off("ice-candidate", handleIceCandidate);
        socket.off("offer");
        socket.off("answer");
      };
    }
  }, [socket, handleIceCandidate, dispatch]);

  useEffect(() => {
    if (socket && peer.peer && modalOpen) {
      console.log("Socket and Peer connection are established.");

      // Listen for ICE candidates from the peer
      peer.peer.onicecandidate = (event) => {
        if (event?.candidate) {
          console.log("Generated local ICE candidate:", event?.candidate);
          socket.emit("ice-candidate", {
            rtcMessage: event.candidate,
            callerId: calleeInfo?.id || caller?.user?.id,
          });
          console.log("Sent local ICE candidate to server:", {
            rtcMessage: event.candidate,
            callerId: calleeInfo?.id || caller?.user?.id,
          });
        } else {
          console.log("ICE candidate gathering completed.");
        }
      };

      // Listen for incoming ICE candidates from the other peer
      // socket.on("ice-candidate", handleIceCandidate);

      socket.on("call-ended", (payload) => {
        console.log(
          "Call has been ended by the other party:",
          payload?.user?.name
        );
        // toast.info(`call has been ended by ${payload?.user?.name}`);

        localStream?.getTracks().forEach((track) => track.stop());
        dispatch(closeModal());
        setLocalStream(null);
        setRemoteStream(null);
        setMicOn(true);
        setVideoOn(true);
        peer.closeConnection();
      });

      socket.on("call-declined", (payload) => {
        console.log("Call was declined by the other party:", payload);
        toast.info(`Call was declined by ${payload.user.name}`);
        localStream?.getTracks().forEach((track) => track.stop());
        dispatch(closeModal());
        setLocalStream(null);
        setMicOn(true);
        setVideoOn(true);
        peer.closeConnection();
      });

      return () => {
        console.log("Cleaning up ICE candidate listeners.");
        if (peer.peer) {
          // socket.off("ice-candidate",handleIceCandidate);
          socket.off("call-declined");
          socket.off("call-ended");
        }
      };
    } else {
      if (!socket) {
        console.log("Socket is not initialized yet.");
      }
      if (!peer.peer) {
        console.log("Peer connection is not yet established.");
      }
    }
  }, [socket, calleeInfo, caller, localStream, dispatch, modalOpen]);

  const handleDeclineCall = () => {
    const payload = {
      callerId: caller?.user?.id || calleeInfo?.id,
      user: {
        id: currentUser.id,
        name: currentUser.username,
      },
    };

    socket.emit("decline-call", payload);
    localStream?.getTracks().forEach((track) => track.stop());
    dispatch(closeModal());
    setLocalStream(null);
    setRemoteStream(null);
    setMicOn(true);
    setVideoOn(true);
  };

  const handleEndCall = () => {
    const payload = {
      callerId: caller?.user?.id || calleeInfo?.id,
      user: {
        id: currentUser.id,
        name: currentUser.username,
      },
    };

    socket.emit("end-call", payload);
    localStream?.getTracks().forEach((track) => track.stop());
    dispatch(closeModal());
    setLocalStream(null);
    setRemoteStream(null);
    setMicOn(true);
    setVideoOn(true);
    peer.closeConnection();
  };

  const toggleMic = () => {
    setMicOn((prev) => !prev);
    localStream
      ?.getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const toggleVideo = () => {
    setVideoOn((prev) => !prev);
    localStream
      ?.getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const renderCallStateText = () => {
    if (isCalling) {
      return `Calling ${calleeInfo?.name || "Unknown User"}...`;
    } else if (isReceivingCall) {
      return `Incoming call from ${caller?.user?.name || "Unknown User"}...`;
    } else if (callInProgress) {
      return `You are in a call with ${
        calleeInfo?.name || caller?.user?.name || "Unknown User"
      }.`;
    } else {
      return "";
    }
  };

  //mui designs

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: isFullScreen ? 0 : 2,
    p: isFullScreen ? 0 : isSmallScreen ? 2 : 4,
    width: isFullScreen ? "100%" : isSmallScreen ? "90%" : "70%",
    height: isFullScreen ? "100%" : "auto",
    maxWidth: isFullScreen ? "none" : 850,
    maxHeight: isFullScreen ? "none" : "90vh", // Adjust max height to prevent scrolling
    overflow: "hidden", // Remove the scroll behavior
  };

  const videoContainerStyle = {
    width: "100%",
    height: isFullScreen ? "calc(100% - 80px)" : "auto",
    aspectRatio: isFullScreen ? "auto" : "16 / 9",
    position: "relative",
    borderRadius: isFullScreen ? "10px" : "10px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    maxHeight: isFullScreen ? "none" : "100%", // Prevent overflow in smaller screens
  };

  const videoStyle = {
    width: "100%",
    height: "100%",
    objectFit: isFullScreen ? "contain" : "cover",
    maxHeight: "100%", // Adjust to prevent overflowing
  };

  const controlsStyle = {
    display: "flex",
    justifyContent: "center",
    gap: 2,
    padding: isFullScreen ? "20px 0" : "10px 0",
    backgroundColor: isFullScreen ? "rgba(0, 0, 0, 0.5)" : "transparent",
    position: isFullScreen ? "absolute" : "relative", // Fix position in full-screen mode
    bottom: isFullScreen ? "0" : "auto",
    width: "100%",
  };

  const streamLabelStyle = {
    position: "absolute",
    bottom: "8px",
    left: "8px", // Changed from center to left corner
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "2px 8px",
    borderRadius: "4px",
    fontSize: "0.9rem",
    zIndex: 1,
  };

  const iconButtonStyle = {
    backgroundColor: "#f0f0f0",
    borderRadius: "50%",
    padding: "10px",
    color: "#555",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#000",
    },
  };

  const iconInnerStyle = {
    fontSize: "1.5rem",
  };

  const answerButtonStyle = {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  };

  const declineButtonStyle = {
    borderColor: "red",
    color: "red",
    "&:hover": {
      borderColor: "darkred",
      color: "darkred",
    },
  };

  const endCallButtonStyle = {
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "darkred",
    },
  };

  return (
    <Modal open={modalOpen}>
      <Box sx={modalStyle} ref={fullScreenRef}>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            textAlign: "center",
            mb: 2,
            display: isFullScreen ? "none" : "block",
          }}
        >
          In Call
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 3 }}>
          {renderCallStateText()}
        </Typography>
        {!isReceivingCall && (<Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen && !isFullScreen ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
            gap: 2,
            height: isFullScreen ? "calc(100% - 120px)" : "auto",
            overflow: "hidden", // Prevent scroll behavior
          }}
        >
          {localStream && (
            <Box
              sx={{
                ...videoContainerStyle,
                flex: isFullScreen ? "none" : 1,
                position: isFullScreen ? "absolute" : "relative",
                top: isFullScreen ? "20px" : "auto",
                right: isFullScreen ? "20px" : "auto",
                width: isFullScreen ? "25%" : "100%",
                height: isFullScreen ? "auto" : "100%",
                zIndex: 1,
                maxHeight: "100%", // Prevents overflow
              }}
            >
              <video
                ref={localVideoRef}
                autoPlay
                playsInline
                muted
                style={videoStyle}
              />
              <Typography sx={streamLabelStyle}>You</Typography>
            </Box>
          )}
          {remoteStream && (
            <Box
              sx={{
                ...videoContainerStyle,
                flex: isFullScreen ? 1 : 1,
                maxHeight: "100%",
              }}
            >
              <video
                ref={remoteVideoRef}
                autoPlay
                playsInline
                style={videoStyle}
              />
              <Typography sx={streamLabelStyle}>
                {calleeInfo?.name || caller?.user?.name || "Remote User"}
              </Typography>
            </Box>
          )}
        </Box> )}

        <Box sx={controlsStyle}>
          {isReceivingCall ? (
            <>
              <Button
                variant="contained"
                sx={answerButtonStyle}
                onClick={handleAnswerCall}
              >
                Answer
              </Button>
              <Button
                variant="outlined"
                sx={declineButtonStyle}
                onClick={handleDeclineCall}
              >
                Decline
              </Button>
            </>
          ) : (
            (isCalling || callInProgress) && (
              <>
                <IconButton onClick={toggleMic} sx={iconButtonStyle}>
                  {micOn ? (
                    <MicIcon sx={iconInnerStyle} />
                  ) : (
                    <MicOffIcon sx={iconInnerStyle} />
                  )}
                </IconButton>
                <IconButton onClick={toggleVideo} sx={iconButtonStyle}>
                  {videoOn ? (
                    <VideocamIcon sx={iconInnerStyle} />
                  ) : (
                    <VideocamOffIcon sx={iconInnerStyle} />
                  )}
                </IconButton>
                <IconButton onClick={handleEndCall} sx={endCallButtonStyle}>
                  <CallEndIcon sx={iconInnerStyle} />
                </IconButton>
                <IconButton onClick={toggleFullScreen} sx={iconButtonStyle}>
                  {isFullScreen ? (
                    <FullscreenExitIcon sx={iconInnerStyle} />
                  ) : (
                    <FullscreenIcon sx={iconInnerStyle} />
                  )}
                </IconButton>
              </>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default InCallModal;
