import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import { useSocket } from "../../context/SocketProvider";
import { useDispatch, useSelector } from "react-redux";
import ViewerPeerService from "../../services/ViewerPeerService";
import {
  closeIsModalOpen,
  closeViewerModal,
  endStream,
  setHostId,
} from "../../state/streamSlice";
import { ExitToApp } from "@mui/icons-material";

const ViewerStream = () => {
  const [remoteStream, setRemoteStream] = useState(null);
  const [connectionError, setConnectionError] = useState("");
  const socket = useSocket();
  const currentUser = useSelector((state) => state.auth.user);
  const hostId = useSelector((state) => state.livestream.hostId);
  const dispatch = useDispatch();
  const remoteVideoRef = useRef(null);
  const hostIdRef = useRef(hostId);

  useEffect(() => {
    hostIdRef.current = hostId;
    console.log("host id changed", hostId);
  }, [hostId]);

  useEffect(() => {
    ViewerPeerService.setRemoteStreamCallback((stream) => {
      setRemoteStream(stream);
    });

    ViewerPeerService.setIceCandidateCallback((candidate) => {
      console.log("host id", hostIdRef.current);
      socket.emit("livesteam-ice-candidate", {
        targetId: hostIdRef.current,
        candidate,
      });
    });

    if (socket) {
      socket.on("livesteam-offer", async (payload) => {
        dispatch(setHostId(payload.hostId));
        const answer = await ViewerPeerService.handleOffer(payload.offer);
        if (answer) {
          socket.emit("livesteam-answer", { hostId: payload.hostId, answer });
        }
      });

      socket.on("livesteam-ice-candidate", (candidate) => {
        console.log("received ice candidate from host", candidate);
        ViewerPeerService.addIceCandidate(candidate);
      });

      socket.on("host-disconnected", () => {
        console.log("host disconnected");
        handleLeaveStream();
      });
    }

    return () => {
      ViewerPeerService.closeConnection();
      if (socket) {
        socket.off("livesteam-offer");
        socket.off("livesteam-ice-candidate");
        socket.off("host-disconnected");
      }
    };
  }, [socket, currentUser?.id, dispatch]);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const handleLeaveStream = () => {
    setConnectionError("You have left the stream.");
    dispatch(closeViewerModal());
    dispatch(endStream());
    dispatch(closeIsModalOpen());
    ViewerPeerService.closeConnection();
    if (socket) {
      socket.emit("viewer-left-stream", { hostId: hostIdRef.current });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      {remoteStream ? (
        <Box
          sx={{
            width: "100%",
            maxWidth: "640px",
            aspectRatio: "16/9",
            overflow: "hidden",
            borderRadius: "16px",
          }}
        >
          <video
            ref={remoteVideoRef}
            autoPlay
            playsInline
            controls={false}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "16px",
            }}
          />
        </Box>
      ) : connectionError ? (
        <Typography variant="h6" color="error" gutterBottom>
          {connectionError}
        </Typography>
      ) : (
        <Typography variant="h6" gutterBottom>
          Waiting for host to start the stream...
        </Typography>
      )}
      <IconButton
        color="error"
        onClick={handleLeaveStream}
        sx={{ marginTop: 2 }}
      >
        <ExitToApp fontSize="large" />
      </IconButton>
    </Box>
  );
};

export default ViewerStream;