// callSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCalling: false,
  isReceivingCall: false,
  callInProgress: false,
  modalOpen: false,  // New state to control modal visibility
  caller: null,
  callee: null,
  calleeInfo: null,
  error: null,
};

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setCaller(state, action) {
      state.caller = action.payload;
    },
    setCallee(state, action) {
      state.callee = action.payload;
    },
    setCalleeInfo(state, action) {
      state.calleeInfo = action.payload;
    },
    setIsCalling(state, action) {
      state.isCalling = action.payload;
      state.modalOpen = action.payload || state.isReceivingCall || state.callInProgress;
    },
    setIsReceivingCall(state, action) {
      state.isReceivingCall = action.payload;
      state.modalOpen = action.payload || state.isCalling || state.callInProgress;
    },
    setCallInProgress(state, action) {
      state.callInProgress = action.payload;
      state.modalOpen = action.payload || state.isCalling || state.isReceivingCall;
    },
    closeModal(state) {
      state.modalOpen = false;
      state.isCalling = false;
      state.isReceivingCall = false;
      state.callInProgress = false;
      state.calleeInfo = null;
      state.caller = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  setCaller,
  setCallee,
  setCalleeInfo,
  setIsCalling,
  setIsReceivingCall,
  setCallInProgress,
  closeModal,
  setError,
} = callSlice.actions;

export default callSlice.reducer;
