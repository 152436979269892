import React from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  Badge,
} from "@mui/material";
import { CaretDown, MagnifyingGlass, Phone, VideoCamera } from "phosphor-react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toggleSidebar } from "state/chat";
import { setCalleeInfo, setIsCalling, setIsReceivingCall } from "state/call";
import { setGroupCalleeInfo, setGroupInfo, setIsGroupCalling } from "state/groupCall";

// StyledBadge component for showing online status
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Header = ({ isGroupChat = false }) => {
  const dispatch = useDispatch();
  const roomId = useSelector((state) =>
    isGroupChat ? state.sidebar.group.roomId : state.sidebar.personal.roomId
  );
  const user = useSelector((state) =>
    state.auth.users.find((user) => user.id === roomId)
  );
  const group = useSelector((state) =>
    state.groups.groups.find((group) => group.id === roomId)
  );

  const handleClick = () => {
    const chatType = isGroupChat ? "group" : "personal";
    dispatch(toggleSidebar({ chatType }));
  };

  const handleVideoCallClick = () => {
    if (isGroupChat && group) {
      // Set the group call information
      dispatch(setGroupInfo({
        groupId: group.id,
        groupName: group.naturalName,
        // other relevant group info
      }));
  
      // Start the group call
      dispatch(setIsGroupCalling(true));
    } else if (!isGroupChat && user) {
      // Set the callee information for one-on-one call
      dispatch(setCalleeInfo({
        id: user.id,
        name: user.username,
        // other relevant user info
      }));
  
      // Start the one-on-one call
      dispatch(setIsCalling(true));
    }
  };
  


  if (!user && !group) {
    return null;
  }

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "9vh",
        backgroundColor: "#F8FAFF",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        alignItems={"center"}
        direction="row"
        justifyContent={"space-between"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack
          onClick={handleClick}
          direction={"row"}
          spacing={2}
          sx={{ cursor: "pointer" }}
        >
          <Box>
            {isGroupChat ? (
              <Avatar
                alt={isGroupChat ? group.naturalName : user.username}
                src={isGroupChat ? group.image : user.image}
              />
            ) : (
              // <StyledBadge
              //   overlap="circular"
              //   anchorOrigin={{
              //     vertical: "bottom",
              //     horizontal: "right",
              //   }}
              //   variant="dot"
              // >
                <Avatar
                  alt={isGroupChat ? group.naturalName : user.username}
                  src={isGroupChat ? group.image : user.image}
                />
              // </StyledBadge>
            )}
          </Box>
          <Stack spacing={0.2}>
            <Typography variant="subtitle2">
              {isGroupChat ? group.naturalName : user.username}
            </Typography>
            {/* <Typography variant="caption">Online</Typography> */}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems={"center"} spacing={3}>
          <IconButton onClick={handleVideoCallClick}>
            <VideoCamera />
          </IconButton>
          
          <IconButton>
            <MagnifyingGlass />
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <IconButton>
            <CaretDown />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

Header.propTypes = {
  isGroupChat: PropTypes.bool,
};

export default Header;
