import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Box, Typography, IconButton, Badge } from "@mui/material";
import { useSocket } from "../../context/SocketProvider";
import { useDispatch, useSelector } from "react-redux";
import { closeHostModal, closeIsModalOpen, endStream } from "../../state/streamSlice";
import LiveStreamPeerService from "../../services/LiveStreamPeerService";
import { ExitToApp, Mic, MicOff, Videocam, VideocamOff, Visibility } from "@mui/icons-material";

const HostStream = () => {
  const dispatch = useDispatch();
  const [localStream, setLocalStream] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [micOn, setMicOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);
  const [viewerCount, setViewerCount] = useState(0);
  const videoRef = useRef(null);
  
  const currentUser = useSelector((state) => state.auth.user);
  const { isHost, hostRoomId } = useSelector((state) => state.livestream);
  const socket = useSocket();

  useEffect(() => {
    if (isHost) {
      startStream();
    }

    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
      LiveStreamPeerService.closeAllConnections();
    };
  }, [isHost]);

  useEffect(() => {
    if (videoRef.current && localStream) {
      videoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  const startStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);
      setIsStreaming(true);
      LiveStreamPeerService.setLocalStream(stream);
    } catch (error) {
      console.error("Failed to start stream:", error);
    }
  }, []);

  const handleViewerConnected = useCallback(async (viewerId) => {
    try {
      console.log("viewer connected", viewerId);
      const peerConnection = LiveStreamPeerService.createPeerConnection(viewerId);
      const offer = await LiveStreamPeerService.createOffer(viewerId);
      socket.emit("livesteam-offer", { viewerId, offer });
      setViewerCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error(`Error handling viewer connection for ${viewerId}:`, error);
    }
  }, [socket]);

  const handleIceCandidate = useCallback(({ targetId, candidate }) => {
    console.log("ice candidate received from viewer", targetId);
    LiveStreamPeerService.addIceCandidate(targetId, candidate);
  }, []);

  const handleAnswer = useCallback(async ({ viewerId, answer }) => {
    await LiveStreamPeerService.setRemoteDescription(viewerId, answer);
  }, []);

  useEffect(() => {
    LiveStreamPeerService.setIceCandidateCallback((viewerId, candidate) => {
      socket.emit("livesteam-ice-candidate", {
        targetId: viewerId,
        candidate: candidate,
      });
    });

    if (localStream && socket) {
      socket.on("viewer-connected", handleViewerConnected);
      socket.on("livesteam-ice-candidate", handleIceCandidate);
      socket.on("livesteam-answer", handleAnswer);
    }

    return () => {
      if (socket) {
        socket.off("viewer-connected", handleViewerConnected);
        socket.off("livesteam-ice-candidate", handleIceCandidate);
        socket.off("livesteam-answer", handleAnswer);
      }
    };
  }, [handleViewerConnected, handleIceCandidate, handleAnswer, localStream, socket]);

  const stopStream = useCallback(() => {
    socket.emit("livesteam-drop", {
      livesteamId: hostRoomId,
      isHost: true,
      userId: currentUser?.id,
    });

    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    setIsStreaming(false);
    setLocalStream(null);
    dispatch(closeHostModal());
    dispatch(closeIsModalOpen());
    dispatch(endStream());
    setViewerCount(0);
    LiveStreamPeerService.closeAllConnections();
  }, [localStream, dispatch, socket, hostRoomId, currentUser?.id]);

  const toggleMic = useCallback(() => {
    setMicOn((prev) => !prev);
    localStream?.getAudioTracks().forEach((track) => (track.enabled = !track.enabled));
  }, [localStream]);

  const toggleVideo = useCallback(() => {
    setVideoOn((prev) => !prev);
    localStream?.getVideoTracks().forEach((track) => (track.enabled = !track.enabled));
  }, [localStream]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 2 }}>
      <Box sx={{ position: "relative", width: "100%", maxWidth: "640px", aspectRatio: "16/9", borderRadius: "16px", overflow: "hidden" }}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "16px",
          }}
        />
        {!videoOn && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <Typography variant="h6" color="white">
              Camera is off
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, marginTop: 2 }}>
        <Badge
          badgeContent={viewerCount}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              right: -3,
              top: 13,
              border: `2px solid white`,
              padding: '0 4px',
            },
          }}
        >
          <Visibility sx={{ fontSize: 40, color: "#1976d2" }} />
        </Badge>

        <IconButton color={videoOn ? "primary" : "error"} onClick={toggleVideo}>
          {videoOn ? <Videocam fontSize="large" /> : <VideocamOff fontSize="large" />}
        </IconButton>
        <IconButton color={micOn ? "primary" : "error"} onClick={toggleMic}>
          {micOn ? <Mic fontSize="large" /> : <MicOff fontSize="large" />}
        </IconButton>
        <IconButton color="error" onClick={stopStream}>
          <ExitToApp fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HostStream;