import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  File,
  Image,
  LinkSimple,
  PaperPlaneTilt,
  Smiley,
  Microphone,
  MicrophoneSlash,
} from "phosphor-react";
import { useTheme, styled, alpha } from "@mui/material/styles";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage, sendNotification } from "state/chat";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
  },
  "& .MuiInputAdornment-positionStart": {
    marginTop: "0px !important",
  },
}));

const Actions = [
  {
    color: "#0159b2",
    icon: <File size={24} />,
    y: 120,
    title: "Document",
  },
];

const ChatInput = ({
  openPicker,
  setOpenPicker,
  inputValue,
  setInputValue,
  handleSendMessage,
  handleFileUpload,
  uploading,
  imageUrl,
  handleAudioUpload,
  recording,
  startRecording,
  stopRecording,
}) => {
  const [openActions, setOpenActions] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <StyledInput
      fullWidth
      placeholder="Write a message..."
      variant="filled"
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        disableUnderline: true,
        // startAdornment: (
        //   <Stack sx={{ width: "max-content" }}>
        //     {/* <Stack
        //       sx={{
        //         position: "relative",
        //         display: openActions ? "inline-block" : "none",
        //       }}
        //     >
        //       {Actions.map((el) => (
        //         <Tooltip key={el.title} placement="right" title={el.title}>
        //           <Fab
        //             sx={{
        //               position: "absolute",
        //               top: -el.y,
        //               backgroundColor: el.color,
        //             }}
        //           >
        //             {el.icon}
        //           </Fab>
        //         </Tooltip>
        //       ))}
        //     </Stack> */}
        //     <InputAdornment position="start">
        //       <IconButton
        //         onClick={() => {
        //           setOpenActions((prev) => !prev);
        //         }}
        //       >
        //         <LinkSimple />
        //       </IconButton>
        //     </InputAdornment>
        //   </Stack>
        // ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setOpenPicker((prev) => !prev);
              }}
            >
              <Smiley />
            </IconButton>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.mp4,.mov,.avi,.wmv"
              style={{ display: "none" }}
              id="file-input"
              onChange={(e) => handleFileUpload(e.target.files[0])}
            />
            <label htmlFor="file-input" style={{ cursor: "pointer" }}>
              <IconButton component="span">
                {uploading ? <CircularProgress size={24} /> : <Image />}
              </IconButton>
            </label>
            <IconButton
              onClick={recording ? stopRecording : startRecording}
              color={recording ? "error" : "default"}
            >
              {recording ? <MicrophoneSlash /> : <Microphone />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const Footer = ({ isGroupchat }) => {
  const theme = useTheme();
  const [openPicker, setOpenPicker] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const dispatch = useDispatch();
  const pickerRef = useRef(null);

  const chatType = isGroupchat ? "group" : "personal";
  const name = useSelector((state) =>
    isGroupchat ? state.sidebar.group.roomId : state.sidebar.personal.name
  );
  const { username, firstName, lastName, image } = useSelector(
    (state) => state.auth.user
  );
  const token = useSelector((state) => state.auth.token);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const oppositeUserId = useSelector((state) => state.sidebar.personal.roomId);
  const xmppConnected = useSelector((state) => state.sidebar.xmppConnected);

  const handleFileUpload = async (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${baseURL}/upload/multiple`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      const data = await response.json();
      if (response.ok && data && data.length > 0) {
        setImageUrl(data[0].url);
        setInputValue(data[0].url);
      } else {
        console.error("File upload failed:", data);
      }
    } catch (error) {
      console.error("File upload error:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      dispatch(
        sendMessage({
          chatType,
          name,
          username,
          message: inputValue,
          groupDetails: isGroupchat
            ? {
                roomJid: `${name}`, // Adjust the room JID as needed
                firstName: firstName, // Assuming user's first name is same as username
                lastName: lastName, // Assuming no last name for simplicity
                photo: image, // Provide a photo URL if available
                walletAddress: "", // Provide a wallet address if needed
                notDisplayedValue: "", // Additional value if needed
              }
            : {},
        })
      );

      if (!isGroupchat && xmppConnected) {
        dispatch(
          sendNotification({
            receiverUserId: oppositeUserId, // assuming 'name' is the receiver's user ID in personal chat
            message: inputValue,
            token: token,
            username: username,
          })
        );
      }
      setInputValue("");
      setImageUrl("");
    }
  };

  const handleAudioUpload = async (blob) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", blob, "audio.webm");

    try {
      const response = await fetch(`${baseURL}/upload/multiple`, {
        method: "POST",
        headers: { 
          Authorization: `Bearer ${token}`,
          // Remove 'Content-Type' header to let the browser set it with the correct boundary for FormData
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Audio upload response:", data);

      if (data && data.length > 0) {
        dispatch(
          sendMessage({
            chatType,
            name,
            username,
            message: data[0].url,
            groupDetails: isGroupchat
              ? {
                  roomJid: `${name}`,
                  firstName: firstName,
                  lastName: lastName,
                  photo: image,
                  walletAddress: "",
                  notDisplayedValue: "",
                }
              : {},
          })
        );
      } else {
        console.error("Audio upload failed: No URL in response");
      }
    } catch (error) {
      console.error("Audio upload error:", error);
      // You might want to show an error message to the user here
    } finally {
      setUploading(false);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newRecorder = new RecordRTC(stream, {
        type: "audio",
        recorderType: StereoAudioRecorder,
      });

      setRecorder(newRecorder);
      newRecorder.startRecording();
      setRecording(true);
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        handleAudioUpload(blob);
        setRecording(false);
      });
    }
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setOpenPicker(false);
    }
  };

  useEffect(() => {
    if (pickerRef.current) {
      pickerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [openPicker]);

  useEffect(() => {
    if (openPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPicker]);

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: { xs: "auto", sm: "9vh" },
        backgroundColor:
          theme.palette.mode === "light"
            ? alpha(theme.palette.background.paper, 1)
            : theme.palette.background.default,
        boxShadow: `0px 0px 2px ${alpha(theme.palette.grey[900], 0.25)}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={3} sx={{ flex: 1 }}>
        <Stack sx={{ width: "100%" }}>
          <Box
            sx={{
              display: openPicker ? "inline" : "none",
              zIndex: 10,
              width: "max-content",
              position: "fixed",
              bottom: 81,
              right: 100,
            }}
            ref={pickerRef}
          >
            <Picker
              data={data}
              onEmojiSelect={(emoji) =>
                setInputValue((prev) => prev + emoji.native)
              }
            />
          </Box>
          <ChatInput
            openPicker={openPicker}
            setOpenPicker={setOpenPicker}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSendMessage={handleSendMessage}
            handleFileUpload={handleFileUpload}
            uploading={uploading}
            imageUrl={imageUrl}
            handleAudioUpload={handleAudioUpload}
            recording={recording}
            startRecording={startRecording}
            stopRecording={stopRecording}
          />
        </Stack>
        <Box
          component={IconButton}
          sx={{
            height: 48,
            width: 48,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={handleSendMessage}
        >
          <PaperPlaneTilt color="#ffffff" />
        </Box>
      </Stack>
    </Box>
  );
};

export default Footer;
