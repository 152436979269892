class ViewerPeerService {
    constructor() {
      this.peerConnection = null;
      this.remoteStream = null;
    }
  
    // Create a new peer connection
    createPeerConnection() {
      if (this.peerConnection) {
        console.warn("Peer connection already exists!");
      } else {
        console.log("Creating a new peer connection.");
        this.peerConnection = new RTCPeerConnection({
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun1.l.google.com:19302" },
          ],
        });
    
        // Handle incoming tracks to set the remote stream
        this.peerConnection.ontrack = (event) => {
          this.remoteStream = event.streams[0];
          console.log("Received remote stream:", this.remoteStream);
          if (this.onRemoteStream) {
            this.onRemoteStream(this.remoteStream);
          }
        };
    
        // Handle ICE candidates
        this.peerConnection.onicecandidate = (event) => {
          if (event.candidate && this.onIceCandidate) {
            console.log("ICE candidate generated:", event.candidate);
            this.onIceCandidate(event.candidate);
          }
        };
      }
      return this.peerConnection;
    }
  
    // Set a callback to handle the remote stream
    setRemoteStreamCallback(callback) {
      this.onRemoteStream = callback;
    }
  
    // Set a callback to handle ICE candidates
    setIceCandidateCallback(callback) {
      this.onIceCandidate = callback;
    }
  
    // Handle the offer from the host and create an answer
    async handleOffer(offer) {
      if (!this.peerConnection) {
        console.log("No peer connection exists, creating a new one.");
        this.createPeerConnection();
      } else {
        console.log("Using existing peer connection to handle the offer.");
      }
  
      try {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
        console.log("Remote description set:", offer);
        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);
        console.log("Created and set local description for the answer.");
        return answer; // Return the answer to be sent to the host
      } catch (error) {
        console.error("Error handling offer in ViewerPeerService:", error);
      }
    }
  
    // Add an ICE candidate received from the host
    async addIceCandidate(candidate) {
      if (this.peerConnection) {
        try {
          console.log("ice candidate from the host",candidate.candidate);
          
          await this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate.candidate));
          console.log("Added ICE candidate:", candidate.candidate);
        } catch (error) {
          console.error("Error adding ICE candidate in ViewerPeerService:", error);
        }
      } else {
        console.warn("No peer connection exists to add ICE candidate.");
      }
    }
  
    // Close the peer connection
    closeConnection() {
      if (this.peerConnection) {
        console.log("Closing the peer connection.");
        this.peerConnection.close();
        this.peerConnection = null;
      } else {
        console.warn("No peer connection exists to close.");
      }
    }
  }
  
  export default new ViewerPeerService();
  